<template>
  <v-container class="py-6">
    <v-responsive max-width="700" class="mx-auto text-center">
      <div class="text-overline secondary--text mb-3">{{ $lang.labels.account }}</div>
      <h2 class="text-h3 text-lg-h2 mb-6">{{ $lang.labels.yourAccount }}</h2>
    </v-responsive>

    <v-card class="pa-4 mx-auto" max-width="600">
      <v-row v-if="err" dense no-gutters class="px-3">
        <v-col v-if="err" cols="12">
          <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
        </v-col>
      </v-row>
      <v-card-text>
        <v-text-field v-model="formData.email" label="Email" outlined @keyup.enter="submit()"></v-text-field>
        <v-text-field
          v-model="formData.password"
          :append-icon="passwordEye ? 'mdi-eye' : 'mdi-eye-off'"
          :type="passwordEye ? 'text' : 'password'"
          label="Password"
          outlined
          hide-details
          @click:append="passwordEye = !passwordEye"
          @keyup.enter="submit()"
        ></v-text-field>
        <v-btn
          block
          class="accent mt-4 color-accent-text"
          x-large
          :disabled="loadingLogin"
          :loading="loadingLogin"
          @click="submit()"
        >Sign In</v-btn>
        <div class="text-center mt-2 text-body-2">
          <router-link :to="{ name: 'forgot-password'}">{{ $lang.labels.forgotYourPassword }}</router-link>
        </div>
      </v-card-text>
    </v-card>
    <div class="text-overline text-uppercase mt-3 text-center">
      By signing in, you agree to the
      <a :href="`/${$lang.current_lang}/terms-and-Conditions`" target="_blank">{{ $lang.labels.tos }}</a> & <a :href="`/${$lang.current_lang}/data-protection-policy`" target="_blank">{{ $lang.labels.privacyPolicy }}</a>
    </div>
  </v-container>
</template>
<script>
import auth from '@/auth/index'
import {
  loginUsingPOST as login
} from '@/utils/api'

export default {
  data() {
    return {
      loadingLogin: false,
      formData: {
        email: '',
        password: ''
      },
      err: '',
      passwordEye: false
    }
  },
  mounted() {
  },
  methods: {
    submit() {
      this.err = ''
      this.loadingLogin = true
      // Check if email/Password are empty
      if (this.formData.email.length === 0) {
        this.err = this.$lang.errors.missingUsername
        this.loadingLogin = false

        return
      }
      if (this.formData.password.length === 0) {
        this.err = this.$lang.errors.missingPassword
        this.loadingLogin = false

        return
      }
      this.formData.email = this.formData.email.toLowerCase().trim()
      // Authenticate user
      login({ loginRequest: this.formData })
        .then((res) => {
          if (res.data.status === 'SUCCESS') {

            localStorage.userData = JSON.stringify(res.data.data)
            localStorage.savedLocations = '[]'
            auth.userData.loggedInStatus = true
            auth.userData.token = res.data.data.authToken

            if (this.$route.query && this.$route.query.redirect) {
              window.open(this.$route.query.redirect,'_self')
            } else {
              window.open('/','_self')
            }

            return
          }
          this.loadingLogin = false
          this.err = res.data.statusText
        })
        .catch((err) => {
          console.log(err)
          this.err = this.$lang.errors.somethingWentWrong
          this.loadingLogin = false
        })

    }
  }
}
</script>
